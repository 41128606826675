<template>
    <div>
        <img alt="overlord logo" src="../../assets/overlord.svg">
    </div>
</template>

<script type="text/javascript">
export default {
    name: 'AppLogo',
    data() {
        return {

        }
    },
}
</script>

<style lang='less' scoped>
img {
    max-width: 100%;
}
</style>