<template>
    <div class="NotFoundContainer">
        <h2>404 NotFound</h2>
    </div>
</template>

<script type="text/javascript">
export default {
    name: 'NotFound',
    data() {
        return {

        }
    },
}
</script>

<style lang='less' scoped>
.NotFoundContainer {
    font-size: 50px;
    max-width: 100%;
    min-height: calc(100vh - 40px);
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
}
</style>