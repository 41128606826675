const config = {
  main_domain: "overlord.img.cf.nanoc.work",
  branch_domain: "overlord.img.cf.nanoc.work",
  other_domain: "overlord.img.cf.nanoc.work",
  // main_domain: "overlord.cdn.bunny.nanoc.work",
  // branch_domain: "overlord.cdn.bunny.nanoc.work",
  // other_domain: "overlord.cdn.bunny.nanoc.work",
  // main_domain: "overlord.vercel.nanoc.work/api",
  // branch_domain: "overlord.vercel.nanoc.work/api",
  // other_domain: "overlord.vercel.nanoc.work/api",
  main: {
    dir: "main",
    index: [
      {
        id: "0",
        cn: "序章",
        tw: "序章",
        end: 234,
        menu: [
          {
            cn: "Prologue 上",
            tw: "Prologue 上",
            start: 1,
            end: 52,
          },
          {
            cn: "Prologue 下",
            tw: "Prologue 下",
            start: 158,
            end: 234,
          },
        ],
      },
      {
        id: "1",
        cn: "不死者之王",
        tw: "不死者之王",
        end: 327,
        menu: [
          {
            cn: "不死者之王",
            tw: "不死者之王",
            start: 1,
            end: 11,
          },
          {
            cn: "Prologue",
            tw: "Prologue",
            start: 12,
            end: 15,
          },
          {
            cn: "开始与结束",
            tw: "開始與結束",
            start: 16,
            end: 70,
          },
          {
            cn: "楼层守护者",
            tw: "樓層守護者",
            start: 71,
            end: 157,
          },
          {
            cn: "卡恩村之战",
            tw: "卡恩村之戰",
            start: 158,
            end: 225,
          },
          {
            cn: "冲突",
            tw: "沖突",
            start: 226,
            end: 284,
          },
          {
            cn: "死之统治者",
            tw: "死之統治者",
            start: 285,
            end: 315,
          },
          {
            cn: "Epilogue",
            tw: "Epilogue",
            start: 316,
            end: 324,
          },
          {
            cn: "后记",
            tw: "後記",
            start: 325,
            end: 327,
          },
        ],
      },
      {
        id: "2",
        cn: "黑暗战士",
        tw: "黑暗戰士",
        end: 410,
        menu: [
          {
            cn: "黑暗战士",
            tw: "黑暗戰士",
            start: 1,
            end: 11,
          },
          {
            cn: "Prologue",
            tw: "Prologue",
            start: 12,
            end: 20,
          },
          {
            cn: "两个冒险者",
            tw: "兩個冒險者",
            start: 21,
            end: 112,
          },
          {
            cn: "旅程",
            tw: "旅程",
            start: 113,
            end: 190,
          },
          {
            cn: "幕间",
            tw: "幕間",
            start: 191,
            end: 203,
          },
          {
            cn: "森林贤王",
            tw: "森林賢王",
            start: 205,
            end: 281,
          },
          {
            cn: "致命双剑",
            tw: "致命雙劍",
            start: 283,
            end: 402,
          },
          {
            cn: "Epilogue",
            tw: "Epilogue",
            start: 403,
            end: 406,
          },
          {
            cn: "后记",
            tw: "后记",
            start: 407,
            end: 410,
          },
        ],
      },
      {
        id: "3",
        cn: "鲜血的战争少女",
        tw: "鮮血的戰爭少女",
        end: 356,
        menu: [
          {
            cn: "鲜血的战争少女",
            tw: "鮮血的戰爭少女",
            start: 1,
            end: 15,
          },
          {
            cn: "捕食者集团",
            tw: "捕食者集團",
            start: 16,
            end: 60,
          },
          {
            cn: "真祖",
            tw: "真祖",
            start: 62,
            end: 146,
          },
          {
            cn: "混乱与掌握",
            tw: "混亂與掌握",
            start: 148,
            end: 228,
          },
          {
            cn: "面临死战",
            tw: "面臨死戰",
            start: 230,
            end: 280,
          },
          {
            cn: "PVN",
            tw: "PVN",
            start: 282,
            end: 348,
          },
          {
            cn: "Epilogue",
            tw: "Epilogue",
            start: 349,
            end: 352,
          },
          {
            cn: "后记",
            tw: "后记",
            start: 353,
            end: 356,
          },
        ],
      },
      {
        id: "4",
        cn: "蜥蜴人勇者",
        tw: "蜥蜴人勇者",
        end: 358,
        menu: [
          {
            cn: "蜥蜴人勇者",
            tw: "蜥蜴人勇者",
            start: 1,
            end: 9,
          },
          {
            cn: "Prologue",
            tw: "Prologue",
            start: 10,
            end: 21,
          },
          {
            cn: "启程",
            tw: "啟程",
            start: 22,
            end: 60,
          },
          {
            cn: "集结的蜥蜴人",
            tw: "集結的蜥蜴人",
            start: 61,
            end: 126,
          },
          {
            cn: "幕间",
            tw: "幕間",
            start: 127,
            end: 132,
          },
          {
            cn: "死亡军团",
            tw: "死亡軍團",
            start: 133,
            end: 230,
          },
          {
            cn: "绝望的序幕",
            tw: "絕望的序幕",
            start: 231,
            end: 296,
          },
          {
            cn: "冰冻的武神",
            tw: "冰凍的武神",
            start: 297,
            end: 349,
          },
          {
            cn: "Epilogue",
            tw: "Epilogue",
            start: 350,
            end: 355,
          },
          {
            cn: "后记",
            tw: "後記",
            start: 356,
            end: 358,
          },
        ],
      },
      {
        id: "5",
        cn: "王国好汉(上)",
        tw: "王國好漢(上)",
        end: 305,
        menu: [
          {
            cn: "王国好汉(上)",
            tw: "王國好漢(上)",
            start: 1,
            end: 14,
          },
          {
            cn: "Prologue",
            tw: "Prologue",
            start: 15,
            end: 25,
          },
          {
            cn: "少年的心意",
            tw: "少年的心意",
            start: 26,
            end: 68,
          },
          {
            cn: "苍蔷薇",
            tw: "蒼薔薇",
            start: 69,
            end: 117,
          },
          {
            cn: "过场",
            tw: "過場",
            start: 118,
            end: 125,
          },
          {
            cn: "搭救者与获救者",
            tw: "搭救者與獲救者",
            start: 126,
            end: 190,
          },
          {
            cn: "好汉齐聚",
            tw: "好漢齊聚",
            start: 191,
            end: 239,
          },
          {
            cn: "熄火，漫天火花",
            tw: "熄火，漫天火花",
            start: 240,
            end: 303,
          },
          {
            cn: "后记",
            tw: "後記",
            start: 304,
            end: 305,
          },
        ],
      },
      {
        id: "6",
        cn: "王国好汉(下)",
        tw: "王國好漢(下)",
        end: 360,
        menu: [
          {
            cn: "王国好汉(下)",
            tw: "王國好漢(下)",
            start: 1,
            end: 14,
          },
          {
            cn: "王都动乱序章",
            tw: "王都動亂序章",
            start: 15,
            end: 59,
          },
          {
            cn: "袭击前准备",
            tw: "襲擊前准備",
            start: 60,
            end: 110,
          },
          {
            cn: "六臂",
            tw: "六臂",
            start: 111,
            end: 161,
          },
          {
            cn: "亚达巴沃",
            tw: "亞達巴沃",
            start: 162,
            end: 203,
          },
          {
            cn: "过场",
            tw: "過場",
            start: 204,
            end: 208,
          },
          {
            cn: "最強至上的王牌",
            tw: "最強至上的王牌",
            start: 209,
            end: 258,
          },
          {
            cn: "动乱最终决战",
            tw: "動亂最終決戰",
            start: 259,
            end: 339,
          },
          {
            cn: "Epilogue",
            tw: "Epilogue",
            start: 340,
            end: 357,
          },
          {
            cn: "后记",
            tw: "後記",
            start: 358,
            end: 360,
          },
        ],
      },
      {
        id: "7",
        cn: "大坟墓的入侵者",
        tw: "大墳墓的入侵者",
        end: 331,
        menu: [
          {
            cn: "大坟墓的入侵者",
            tw: "大墳墓的入侵者",
            start: 1,
            end: 22,
          },
          {
            cn: "Prologue",
            tw: "Prologue",
            start: 23,
            end: 36,
          },
          {
            cn: "死亡邀约",
            tw: "死亡邀約",
            start: 37,
            end: 92,
          },
          {
            cn: "落入蜘蛛网的蝴蝶",
            tw: "落入蜘蛛網的蝴蝶",
            start: 93,
            end: 176,
          },
          {
            cn: "大坟墓",
            tw: "大墳墓",
            start: 177,
            end: 239,
          },
          {
            cn: "过场",
            tw: "過場",
            start: 240,
            end: 245,
          },
          {
            cn: "一线希望",
            tw: "一線希望",
            start: 246,
            end: 325,
          },
          {
            cn: "Epilogue",
            tw: "Epilogue",
            start: 326,
            end: 331,
          },
        ],
      },
      {
        id: "8",
        cn: "两位领导者",
        tw: "兩位領導者",
        end: 363,
        menu: [
          {
            cn: "两位领导者",
            tw: "兩位領導者",
            start: 1,
            end: 9,
          },
          {
            cn: "安莉慌张忙乱的每一天",
            tw: "安莉慌張忙亂的每一天",
            start: 10,
            end: 194,
          },
          {
            cn: "纳萨力克的一天",
            tw: "納薩力克的一天",
            start: 195,
            end: 352,
          },
          {
            cn: "角色介绍",
            tw: "角色介紹",
            start: 353,
            end: 359,
          },
          {
            cn: "后记",
            tw: "後記",
            start: 360,
            end: 363,
          },
        ],
      },
      {
        id: "9",
        cn: "破军的魔法吟唱者",
        tw: "破軍的魔法吟唱者",
        end: 375,
        menu: [
          {
            cn: "破军的魔法吟唱者",
            tw: "破軍的魔法吟唱者",
            start: 1,
            end: 7,
          },
          {
            cn: "Prologue",
            tw: "Prologue",
            start: 8,
            end: 18,
          },
          {
            cn: "脣枪舌战",
            tw: "脣槍舌戰",
            start: 19,
            end: 103,
          },
          {
            cn: "备战",
            tw: "備戰",
            start: 104,
            end: 189,
          },
          {
            cn: "过场",
            tw: "過場",
            start: 190,
            end: 196,
          },
          {
            cn: "另一场战争",
            tw: "另一場戰爭",
            start: 197,
            end: 263,
          },
          {
            cn: "大屠杀",
            tw: "大屠殺",
            start: 265,
            end: 340,
          },
          {
            cn: "Epilogue",
            tw: "Epilogue",
            start: 341,
            end: 350,
          },
          {
            cn: "新章",
            tw: "新章",
            start: 351,
            end: 362,
          },
          {
            cn: "角色介绍",
            tw: "角色介紹",
            start: 363,
            end: 370,
          },
          {
            cn: "后记",
            tw: "後記",
            start: 371,
            end: 375,
          },
        ],
      },
      {
        id: "10",
        cn: "谋略的统治者",
        tw: "謀略的統治者",
        end: 372,
        menu: [
          {
            cn: "谋略的统治者",
            tw: "謀略的統治者",
            start: 1,
            end: 5,
          },
          {
            cn: "Prologue",
            tw: "Prologue",
            start: 6,
            end: 8,
          },
          {
            cn: "安兹‧乌尔‧恭魔导国",
            tw: "安茲‧烏爾‧恭魔導國",
            start: 9,
            end: 108,
          },
          {
            cn: "里‧耶斯提杰王国",
            tw: "裏‧耶斯提傑王國",
            start: 109,
            end: 186,
          },
          {
            cn: "过场",
            tw: "過場",
            start: 187,
            end: 197,
          },
          {
            cn: "巴哈斯帝国",
            tw: "巴哈斯帝國",
            start: 199,
            end: 351,
          },
          {
            cn: "Epilogue",
            tw: "Epilogue",
            start: 352,
            end: 359,
          },
          {
            cn: "角色介绍",
            tw: "角色介紹",
            start: 360,
            end: 367,
          },
          {
            cn: "后记",
            tw: "後記",
            start: 368,
            end: 372,
          },
        ],
      },
      {
        id: "11",
        cn: "矮人工匠",
        tw: "矮人工匠",
        end: 395,
        menu: [
          {
            cn: "矮人工匠",
            tw: "矮人工匠",
            start: 1,
            end: 5,
          },
          {
            cn: "Prologue",
            tw: "Prologue",
            start: 6,
            end: 16,
          },
          {
            cn: "准备前往未知之地",
            tw: "准備前往未知之地",
            start: 17,
            end: 74,
          },
          {
            cn: "寻找矮人国",
            tw: "尋找矮人國",
            start: 75,
            end: 147,
          },
          {
            cn: "危机将至",
            tw: "危機將至",
            start: 148,
            end: 191,
          },
          {
            cn: "工匠与交涉",
            tw: "工匠與交涉",
            start: 192,
            end: 245,
          },
          {
            cn: "冰霜龙王",
            tw: "冰霜龍王",
            start: 246,
            end: 372,
          },
          {
            cn: "Epilogue",
            tw: "Epilogue",
            start: 373,
            end: 382,
          },
          {
            cn: "角色介绍",
            tw: "角色介紹",
            start: 384,
            end: 390,
          },
          {
            cn: "后记",
            tw: "後記",
            start: 391,
            end: 395,
          },
        ],
      },
      {
        id: "12",
        cn: "圣王国的圣骑士(上)",
        tw: "聖王國的聖騎士(上)",
        end: 332,
        menu: [
          {
            cn: "圣王国的圣骑士(上)",
            tw: "聖王國的聖騎士(上)",
            start: 1,
            end: 5,
          },
          {
            cn: "魔皇亚达巴沃",
            tw: "魔皇亞達巴沃",
            start: 6,
            end: 85,
          },
          {
            cn: "寻求救援",
            tw: "尋求救援",
            start: 86,
            end: 189,
          },
          {
            cn: "反攻作战开始",
            tw: "反攻作戰開始",
            start: 190,
            end: 322,
          },
          {
            cn: "角色介绍",
            tw: "角色介紹",
            start: 324,
            end: 327,
          },
          {
            cn: "后记",
            tw: "後記",
            start: 328,
            end: 332,
          },
        ],
      },
      {
        id: "13",
        cn: "圣王国的圣骑士(下)",
        tw: "聖王國的聖騎士(下)",
        end: 469,
        menu: [
          {
            cn: "圣王国的圣骑士(下)",
            tw: "聖王國的聖騎士(下)",
            start: 1,
            end: 5,
          },
          {
            cn: "攻城战",
            tw: "攻城戰",
            start: 6,
            end: 136,
          },
          {
            cn: "安兹殒命",
            tw: "安茲殞命",
            start: 137,
            end: 251,
          },
          {
            cn: "过场",
            tw: "過場",
            start: 252,
            end: 264,
          },
          {
            cn: "枪兵与弓兵",
            tw: "槍兵與弓兵",
            start: 265,
            end: 361,
          },
          {
            cn: "救国英雄",
            tw: "救國英雄",
            start: 362,
            end: 436,
          },
          {
            cn: "Epilogue",
            tw: "Epilogue",
            start: 437,
            end: 458,
          },
          {
            cn: "角色介绍",
            tw: "角色介紹",
            start: 460,
            end: 464,
          },
          {
            cn: "后记",
            tw: "後記",
            start: 465,
            end: 469,
          },
        ],
      },
      {
        id: "14",
        cn: "灭国的魔女",
        tw: "滅國的魔女",
        end: 424,
        menu: [
          {
            cn: "灭国的魔女",
            tw: "滅國的魔女",
            start: 1,
            end: 7,
          },
          {
            cn: "Prologue",
            tw: "Prologue",
            start: 8,
            end: 32,
          },
          {
            cn: "预料之外的一招",
            tw: "預料之外的一招",
            start: 33,
            end: 115,
          },
          {
            cn: "灭亡之始",
            tw: "滅亡之始",
            start: 116,
            end: 208,
          },
          {
            cn: "最后的国王",
            tw: "最後的國王",
            start: 209,
            end: 257,
          },
          {
            cn: "天罗地网",
            tw: "天羅地網",
            start: 258,
            end: 390,
          },
          {
            cn: "Epilogue",
            tw: "Epilogue",
            start: 391,
            end: 407,
          },
          {
            cn: "角色介绍",
            tw: "角色介紹",
            start: 409,
            end: 414,
          },
          {
            cn: "后记",
            tw: "後記",
            start: 415,
            end: 424,
          },
        ],
      },
    ],
  },
  branch: {
    dir: "branch",
    index: [
      {
        id: "13.5",
        cn: "亡国的吸血姬",
        tw: "亡國的吸血姬",
        end: 338,
        menu: [
          {
            cn: "亡国的吸血姬",
            tw: "亡國的吸血姬",
            start: 1,
            end: 5,
          },
          {
            cn: "Prologue",
            tw: "Prologue",
            start: 6,
            end: 29,
          },
          {
            cn: "亡国的相遇",
            tw: "亡國的相遇",
            start: 30,
            end: 79,
          },
          {
            cn: "两人的启程",
            tw: "兩人的啟程",
            start: 80,
            end: 143,
          },
          {
            cn: "耗时五年的准备",
            tw: "耗時五年的准備",
            start: 144,
            end: 313,
          },
          {
            cn: "Epilogue",
            tw: "Epilogue",
            start: 314,
            end: 334,
          },
          {
            cn: "后记",
            tw: "後記",
            start: 335,
            end: 338,
          },
        ],
      },
    ],
  },
  others: {
    dir: "others",
    index: [
      {
        id: "200",
        cn: "特典 昴宿星团的一日",
        tw: "特典 昴宿星團的一日",
        end: 61,
        menu: [
          {
            cn: "特典 昴宿星团的一日",
            tw: "特典 昴宿星團的一日",
            start: 1,
            end: 3,
          },
          {
            cn: "1",
            tw: "1",
            start: 4,
            end: 11,
          },
          {
            cn: "2",
            tw: "2",
            start: 12,
            end: 23,
          },
          {
            cn: "3",
            tw: "3",
            start: 24,
            end: 38,
          },
          {
            cn: "4",
            tw: "4",
            start: 39,
            end: 50,
          },
          {
            cn: "5",
            tw: "5",
            start: 51,
            end: 57,
          },
          {
            cn: "6",
            tw: "6",
            start: 58,
            end: 61,
          },
        ],
      },
    ],
  },
};

const darkModel = "false";

export default {
  customData: function () {
    return config;
  },
  darkModel,
};
